
import Vue from 'vue'
import {
  createSiteSermonUrl,
  customSitePath,
  foundationsPsalterRootUrl,
  siteShareUrl,
} from '~/assets/ts/utils/urls'
import { CustomSiteText } from '~/apiclient/apicustomsites'
import SaLink from '~/components/_general/SaLink.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import SiteCopyrightText from '~/components/site/CopyrightText.vue'
import SiteButton from '~/components/site/Button.vue'
import SaImage from '~/components/_general/SaImage.vue'
import SiteLayoutDefault from '~/components/site/layout/Default.vue'
import { openPopoutWindow } from '~/assets/ts/utils/misc'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SaSvg from '~/components/_general/SaSvg.vue'

export default Vue.extend({
  name: 'SiteLayoutFoundationsPsalter',
  components: {
    SaSvg,
    InlineIcon,
    SiteLayoutDefault,
    SaImage,
    SiteButton,
    SiteCopyrightText,
    MarkdownElement,
    SaIcon,
    SaLink,
  },
  props: {
    includeDescription: {
      type: Boolean,
    },
  },
  async fetch() {
    await this.$store.dispatch('getFoundationsPsalterCustomText')
  },
  computed: {
    homeUrl(): string {
      return customSitePath(this, 'psalter', foundationsPsalterRootUrl)
    },
    dark(): boolean {
      return this.$colorMode.value === 'dark'
    },
    psalterText(): Record<'body' | 'description', CustomSiteText> {
      return this.$store.getters.customSiteText
    },
    psalterBodyText(): string {
      return this.psalterText.body.body
    },
    year(): number {
      return new Date().getFullYear()
    },
  },
  methods: {
    toggleTheme() {
      this.$colorMode.preference = this.dark ? 'light' : 'dark'
    },
    openVideo() {
      openPopoutWindow(siteShareUrl(createSiteSermonUrl('12102352603749')))
    },
  },
})
