
import Vue from 'vue'
import { TuneListItem } from '~/components/hymnal/Tabbed.vue'
import { TuneApi } from '~/apiclient/apihymns'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import HymnElement from '~/components/_general/HymnElement.vue'

export default Vue.extend({
  name: 'HymnalPsalterTuneList',
  components: { HymnElement, InlineIcon },
  props: {
    foundations: {
      type: Boolean,
    },
  },
  data() {
    return {
      tuneList: [] as TuneListItem[],
    }
  },
  async fetch() {
    const list = (await this.$apiClient.getPrimaryPsalterTunes()).results.sort(
      (a: TuneApi, b: TuneApi) => {
        return a.description.localeCompare(b.description)
      }
    )

    const tunesList = [] as TuneListItem[]

    for (const tunesKey in list) {
      tunesList.push({
        tune: list[tunesKey],
        open: false,
        psalms: [undefined, undefined],
        hasPsalms: false,
      })
    }

    this.tuneList = tunesList
  },
  methods: {
    async openTune(tuneItem: TuneListItem) {
      tuneItem.open = !tuneItem.open
      if (tuneItem.hasPsalms) return

      tuneItem.psalms = (
        await this.$apiClient.getPsalmsFromTune(tuneItem.tune.tuneID)
      ).results
      tuneItem.hasPsalms = true
    },
  },
})
