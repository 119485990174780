
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { images, metadata } from '~/assets/ts/utils/metadata'
import { HymnResultsApi } from '~/apiclient/apihymns'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import { CustomSiteText } from '~/apiclient/apicustomsites'
import SiteLayoutFoundationsPsalter from '~/components/site/layout/FoundationsPsalter.vue'
import HymnalSearchLayout from '~/components/hymnal/SearchLayout.vue'
import NavigationTab from '~/components/_general/NavigationTab.vue'
import ScrollList from '~/components/_general/ScrollList.vue'
import HymnElement from '~/components/_general/HymnElement.vue'
import HymnalPsalterTuneList from '~/components/hymnal/PsalterTuneList.vue'

interface HymnalTab {
  slug: string
  title: TranslateResult
}

export default Vue.extend({
  components: {
    HymnalPsalterTuneList,
    HymnElement,
    ScrollList,
    NavigationTab,
    HymnalSearchLayout,
    SiteLayoutFoundationsPsalter,
  },
  async fetch() {
    await this.getMoreHymns()
  },
  head(this: any) {
    return metadata(this, {
      title: `${this.$t('Foundations Psalter')}`,
      description: this.psalterText.description.body,
      images: images(
        `https://sermonaudio.com/images/og-psalter-foundations.jpg`,
        true
      ),
    })
  },
  computed: {
    ...mapGetters({
      psalterHymns: 'hymns/psalterHymnList',
    }),
    psalterTab(): HymnalTab {
      return {
        title: this.$t('Psalter'),
        slug: 'psalter',
      }
    },
    tuneTab(): HymnalTab {
      return {
        title: this.$t('Psalter Tunes'),
        slug: 'tunes',
      }
    },
    psalterList(): HymnResultsApi[] {
      return getListWithPlaceholders(this.psalterHymns)
    },
    endOfList(): boolean {
      return this.$store.getters['hymns/maxPsalterReached']
    },
    psalterText(): Record<'body' | 'description', CustomSiteText> {
      return this.$store.getters.customSiteText
    },
    tabQuery(): string {
      const tab = this.$route.query.tab
      const valid = tab && ['psalter', 'tunes'].includes(tab.toString())
      return valid ? tab.toString() : 'psalter'
    },
    isPsalter(): boolean {
      return this.tabQuery === this.psalterTab.slug
    },
    isTune(): boolean {
      return this.tabQuery === this.tuneTab.slug
    },
  },
  methods: {
    async getMoreHymns() {
      if (this.isPsalter) {
        await this.$store.dispatch('hymns/fetchPsalterList')
      }
    },
  },
})
